import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { updateDemandFormDataAction } from '../../../../../actions/demand';
import { updateEndpointFormDataAction } from '../../../../../actions/endpoint';

import {
  BID_MODIFY_FIELD,
  SUBID_BID_MODIFY_FIELD,
  CLICK_LIMITS_FIELD,
  DEMAND_WIZARD,
  DMS_DB_FIELD,
  DMS_DB_WEIGHTS_FIELD,
  ENDPOINT_WIZARD,
  GEOS_FIELD,
  LIMITS_FIELD,
  MAX_BID_FIELD,
  MIN_BID_FIELD,
  OPTIMIZATION_FIELD,
  REDIRECT_DOMAINS_FIELD,
  REQUEST_TIMEOUT_FIELD,
  REV_SHARE_FIELD,
  SUB_ID_DB_FIELD,
  VALUE_FIELD
} from '../../../constants';

import { useClearArrayError } from '../../../custom-hooks/useClearArrayError';
import { useFormsEffect } from '../../../custom-hooks/useFormsEffect';
import { useFormsOptions } from '../../../custom-hooks/useFormsOptions';
import { useIsValidForm } from '../../../custom-hooks/useIsValidForm';
import {
  filterAllList,
  handleArrayHaveError,
  getOptions,
  handleArrayErrorMessage
} from '../../../helpers/wizard-helpers';

import { optimizationSchema, validateOptimizationData } from '../../../validators/shared/optimization';

import WizardInputWithValidation from '../../shared/WizardInputWithValidation';
import WizardSelectWithValidation, { CREATE_TYPE, SELECT_TYPE } from '../WizardSelectWithValidation';

import '../../../wizard-base/WizardSharedStyles.scss';

const WizardOptimizationForm = ({
  isSubmitted,
  onSubmit,
  parentWizard,
  setIsValid
}) => {
  const {
    defaultValues,
    countryOptions,
    redirectDomainOptions,
    domainMaskingOptions
  } = useSelector(
    state => ({
      defaultValues: state[parentWizard].formData[OPTIMIZATION_FIELD],
      countryOptions: state.staticList.countryOptions,
      redirectDomainOptions: state.staticList.redirectDomainOptions,
      domainMaskingOptions: state.staticList.domainMaskingOptions
    })
  );

  const {
    register,
    errors,
    triggerValidation,
    control,
    getValues,
    watch,
    clearError
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    validationSchema: optimizationSchema
  });

  const updateState = parentWizard === ENDPOINT_WIZARD ? updateEndpointFormDataAction : updateDemandFormDataAction;

  useFormsEffect(isSubmitted, triggerValidation, getValues, updateState, onSubmit, OPTIMIZATION_FIELD);

  const bidModifyArray = useFieldArray({
    control,
    name: BID_MODIFY_FIELD
  });

  const bidModify = bidModifyArray.fields;
  const appendBid = bidModifyArray.append;
  const removeBid = bidModifyArray.remove;

  const subidBidModifyArray = useFieldArray({
    control,
    name: SUBID_BID_MODIFY_FIELD
  });

  const subidBidModify = subidBidModifyArray.fields;
  const appendSubidBid = subidBidModifyArray.append;
  const removeSubidBid = subidBidModifyArray.remove;

  // DOMAINS MASK WEIGHTS
  const dmsDbWeightsArray = useFieldArray({
    control,
    name: DMS_DB_WEIGHTS_FIELD
  });

  const dmsDbWeights = dmsDbWeightsArray.fields;
  const appendDmsDbWeights = dmsDbWeightsArray.append;
  const removeDmsDbWeights = dmsDbWeightsArray.remove;
  ///*******************

  const limitsArray = useFieldArray({
    control,
    name: LIMITS_FIELD
  });

  const limits = limitsArray.fields;
  const appendLimit = limitsArray.append;
  const removeLimit = limitsArray.remove;

  const revSharesArray = useFieldArray({
    control,
    name: REV_SHARE_FIELD
  });

  const clickLimitsArray = useFieldArray({
    control,
    name: CLICK_LIMITS_FIELD
  });

  const clickLimits = clickLimitsArray.fields;
  const appendClicksLimit = clickLimitsArray.append;
  const removeClicksLimit = clickLimitsArray.remove;

  const revShares = revSharesArray.fields;
  const appendRevShare = revSharesArray.append;
  const removeRevShare = revSharesArray.remove;

  const [limitsCountries, setLimitsCountries] = useState(countryOptions);
  const [clickLimitsCountries, setClickLimitsCountries] = useState(countryOptions);
  const [bidCountries, setBidCountries] = useState(countryOptions);
  const [revShareCountries, setRevShareCountries] = useState(countryOptions);

  const watchLimits = watch(LIMITS_FIELD, defaultValues[LIMITS_FIELD]);
  const watchClickLimits = watch(CLICK_LIMITS_FIELD, defaultValues[CLICK_LIMITS_FIELD]);
  const watchBidModify = watch(BID_MODIFY_FIELD, defaultValues[BID_MODIFY_FIELD]);
  const watchSubidBidModify = watch(SUBID_BID_MODIFY_FIELD, defaultValues[SUBID_BID_MODIFY_FIELD]);
  const watchDmsDbWeights = watch(DMS_DB_WEIGHTS_FIELD, defaultValues[DMS_DB_WEIGHTS_FIELD]);
  const watchRevShare = watch(REV_SHARE_FIELD, defaultValues[REV_SHARE_FIELD]);

  const values = getValues({ nest: true });

  if (watchLimits) {
    values[LIMITS_FIELD] = watchLimits.map((limit) => ({
      [GEOS_FIELD]: limit[GEOS_FIELD],
      [VALUE_FIELD]: limit[VALUE_FIELD]
    }));
  } else {
    values[LIMITS_FIELD] = [];
  }

  if (watchClickLimits) {
    values[CLICK_LIMITS_FIELD] = watchClickLimits.map((click) => ({
      [GEOS_FIELD]: click[GEOS_FIELD],
      [VALUE_FIELD]: click[VALUE_FIELD]
    }));
  } else {
    values[CLICK_LIMITS_FIELD] = [];
  }

  if (watchBidModify) {
    values[BID_MODIFY_FIELD] = watchBidModify.map((bid) => ({
      [GEOS_FIELD]: bid[GEOS_FIELD],
      [VALUE_FIELD]: bid[VALUE_FIELD]
    }));
  } else {
    values[BID_MODIFY_FIELD] = [];
  }

  if (watchSubidBidModify) {
    values[SUBID_BID_MODIFY_FIELD] = watchSubidBidModify.map((bid) => ({
      ['subids']: bid['subids'],
      [VALUE_FIELD]: bid[VALUE_FIELD]
    }));
  } else {
    values[SUBID_BID_MODIFY_FIELD] = [];
  }

  if (watchDmsDbWeights) {
    values[DMS_DB_WEIGHTS_FIELD] = watchDmsDbWeights.map((dmsWeight) => {
        return {
          ['dms_domain']: dmsWeight['dms_domain'],
          [VALUE_FIELD]: dmsWeight[VALUE_FIELD]
        }
    });
  } else {
    values[DMS_DB_WEIGHTS_FIELD] = [];
  }

  if (watchRevShare) {
    values[REV_SHARE_FIELD] = watchRevShare.map((rev) => ({
      [GEOS_FIELD]: rev[GEOS_FIELD],
      [VALUE_FIELD]: rev[VALUE_FIELD]
    }));
  } else {
    values[REV_SHARE_FIELD] = [];
  }

  const isValid = validateOptimizationData(Object.keys(values).length ? values : defaultValues);
  useIsValidForm(isValid, setIsValid);

  useFormsOptions(getOptions(watchLimits || []), countryOptions, setLimitsCountries, GEOS_FIELD, true);
  useFormsOptions(getOptions(watchClickLimits || []), countryOptions, setClickLimitsCountries, GEOS_FIELD, true);
  useFormsOptions(getOptions(watchBidModify || []), countryOptions, setBidCountries, GEOS_FIELD, true);
  useFormsOptions(getOptions(watchRevShare || []), countryOptions, setRevShareCountries, GEOS_FIELD, true);

  useClearArrayError(LIMITS_FIELD, watchLimits, clearError);
  useClearArrayError(CLICK_LIMITS_FIELD, watchLimits, clearError);
  useClearArrayError(BID_MODIFY_FIELD, watchBidModify, clearError);
  useClearArrayError(SUBID_BID_MODIFY_FIELD, watchSubidBidModify, clearError);
  useClearArrayError(REV_SHARE_FIELD, watchRevShare, clearError);
  useClearArrayError(DMS_DB_WEIGHTS_FIELD, watchDmsDbWeights, clearError);

  const filterRedirectDomains = (domains, operation) => {
    if (operation.action === 'select-option') {
      const value = operation.option.value;

      if (value === 'default') {
        return [operation.option];
      }
    }

    return domains.filter(item => item.value !== 'default');
  };

  const filterDMSDomainWeight = (domains, operation) => {
    if (operation.action === 'select-option') {
      const value = operation.option.value;

      if (value === 'rest') {
        return [operation.option];
      }
    }

    return domains.filter(item => item.value !== 'rest');
  };

  return (
    <form className="step-content">
      <div className="group action-right">
        <h4>Daily Limits</h4>

        <div className="group-item pt10">
          <div className="form-group">
            <div className="header-width-add">
              <label className={errors[LIMITS_FIELD] ? 'error' : ''}>
                Max Requests (/day)
              </label>

              <div className="add-new">
                <p onClick={() => appendLimit({
                  value: 0,
                  geos: []
                })}>
                  <i className="fas fa-plus"/>
                  <span className="add-item">Add new</span>
                </p>
              </div>
            </div>

            <div className="row-list">
              {limits.map((limit, index) => {
                return (
                  <div className="row-item" key={limit.id}>
                    <div className="row">
                      <div className="col-sm-4">
                        <WizardInputWithValidation
                          type='number'
                          error={handleArrayHaveError(errors, LIMITS_FIELD, VALUE_FIELD, index)}
                          errorMessage={handleArrayErrorMessage(errors, LIMITS_FIELD, VALUE_FIELD, index)}
                          name={`${LIMITS_FIELD}[${index}][${VALUE_FIELD}]`}
                          propRef={register()}
                          placeholder='Ex: 1000'
                        />
                      </div>

                      <div className="col-sm-8">
                        <WizardSelectWithValidation
                          control={control}
                          error={handleArrayHaveError(errors, LIMITS_FIELD, GEOS_FIELD, index)}
                          errorMessage={handleArrayErrorMessage(errors, LIMITS_FIELD, GEOS_FIELD, index)}
                          isMulti={true}
                          name={`${LIMITS_FIELD}[${index}][${GEOS_FIELD}]`}
                          options={limitsCountries}
                          selectType={SELECT_TYPE}
                          filterSelected={filterAllList}
                          defaultValue={limit[GEOS_FIELD]}
                        />
                      </div>
                    </div>
                    <span
                      className="action-icon delete-item"
                      onClick={() => removeLimit(index)}
                    >
                      <i className="far fa-trash-alt"/>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="group-item pt10">
          <div className="form-group">
            <div className="header-width-add">
              <label className={errors[CLICK_LIMITS_FIELD] ? 'error' : ''}>
                Max Clicks (/day)
              </label>

              <div className="add-new">
                <p onClick={() => appendClicksLimit({
                  value: 0,
                  geos: []
                })}>
                  <i className="fas fa-plus"/>
                  <span className="add-item">Add new</span>
                </p>
              </div>
            </div>

            <div className="row-list">
              {clickLimits.map((click, index) => {
                return (
                  <div className="row-item" key={click.id}>
                    <div className="row">
                      <div className="col-sm-4">
                        <WizardInputWithValidation
                          type='number'
                          error={handleArrayHaveError(errors, CLICK_LIMITS_FIELD, VALUE_FIELD, index)}
                          errorMessage={handleArrayErrorMessage(errors, CLICK_LIMITS_FIELD, VALUE_FIELD, index)}
                          name={`${CLICK_LIMITS_FIELD}[${index}][${VALUE_FIELD}]`}
                          propRef={register()}
                          placeholder='Ex: 1000'
                        />
                      </div>

                      <div className="col-sm-8">
                        <WizardSelectWithValidation
                          control={control}
                          error={handleArrayHaveError(errors, CLICK_LIMITS_FIELD, GEOS_FIELD, index)}
                          errorMessage={handleArrayErrorMessage(errors, CLICK_LIMITS_FIELD, GEOS_FIELD, index)}
                          isMulti={true}
                          name={`${CLICK_LIMITS_FIELD}[${index}][${GEOS_FIELD}]`}
                          options={clickLimitsCountries}
                          selectType={SELECT_TYPE}
                          filterSelected={filterAllList}
                          defaultValue={click[GEOS_FIELD]}
                        />
                      </div>
                    </div>
                    <span
                      className="action-icon delete-item"
                      onClick={() => removeClicksLimit(index)}
                    >
                      <i className="far fa-trash-alt"/>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="group action-right">
        <div className="group">
          <WizardInputWithValidation
            label="Request Timeout [ms]"
            type="number"
            error={!!errors[REQUEST_TIMEOUT_FIELD]}
            errorMessage={errors[REQUEST_TIMEOUT_FIELD] ? errors[REQUEST_TIMEOUT_FIELD].message : ''}
            name={REQUEST_TIMEOUT_FIELD}
            propRef={register}
            placeholder="Ex: 2000"
          />
        </div>

        <div className="group action-right">
          <h4>Bid Optimization</h4>
          <div className="group-item">
            <div className="row-list">
              <div className="row-item">
                <div className="row">
                  <div className="col-sm-6">
                    <WizardInputWithValidation
                        label="Min Bid"
                        type="number"
                        error={!!errors[MIN_BID_FIELD]}
                        errorMessage={errors[MIN_BID_FIELD] ? errors[MIN_BID_FIELD].message : ''}
                        name={MIN_BID_FIELD}
                        propRef={register}
                        placeholder="Ex: 10"
                    />
                  </div>

                  <div className="col-sm-6">
                    <WizardInputWithValidation
                        label="Max Bid"
                        type="number"
                        error={!!errors[MAX_BID_FIELD]}
                        errorMessage={errors[MAX_BID_FIELD] ? errors[MAX_BID_FIELD].message : ''}
                        name={MAX_BID_FIELD}
                        propRef={register}
                        placeholder="Ex: 1,000"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="group-item pt10">
            <div className="form-group">
              <div className="header-width-add">
                <label className={errors[REV_SHARE_FIELD] ? 'error' : ''}>
                  Rev Share
                </label>
                <div className="add-new">
                  <p onClick={() => appendRevShare({
                    value: 0,
                    geos: []
                  })}
                  >
                    <i className="fas fa-plus"/>
                    <span className="add-item">Add new</span>
                  </p>
                </div>
              </div>

              <div className="row-list">
                {revShares.map((revShare, index) => {
                  return (
                      <div className="row-item" key={revShare.id}>
                        <div className="row">
                          <div className="col-sm-4">
                            <WizardInputWithValidation
                                type='number'
                                error={handleArrayHaveError(errors, REV_SHARE_FIELD, VALUE_FIELD, index)}
                                errorMessage={handleArrayErrorMessage(errors, REV_SHARE_FIELD, VALUE_FIELD, index)}
                                name={`${REV_SHARE_FIELD}[${index}][${VALUE_FIELD}]`}
                                propRef={register()}
                                placeholder='Ex: 1000'
                            />
                          </div>
                          <div className="col-sm-8">
                            <WizardSelectWithValidation
                                control={control}
                                error={handleArrayHaveError(errors, REV_SHARE_FIELD, GEOS_FIELD, index)}
                                errorMessage={handleArrayErrorMessage(errors, REV_SHARE_FIELD, GEOS_FIELD, index)}
                                isMulti={true}
                                name={`${REV_SHARE_FIELD}[${index}][${GEOS_FIELD}]`}
                                options={revShareCountries}
                                selectType={SELECT_TYPE}
                                filterSelected={filterAllList}
                                defaultValue={revShare[GEOS_FIELD]}
                            />
                          </div>
                        </div>
                        {revShares.length > 1 && <span
                            onClick={() => removeRevShare(index)}
                            className="action-icon delete-item"
                        >
                        <i className="far fa-trash-alt"/>
                      </span>}
                      </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="group-item pt10">
            <div className="form-group">
              <div className="header-width-add">
                <label>Bid modify</label>
                <div className="add-new">
                  <p onClick={() => appendBid({
                    value: 0,
                    geos: []
                  })}
                  >
                    <i className="fas fa-plus"/>
                    <span className="add-item">Add new</span>
                  </p>
                </div>
              </div>

              <div className="row-list">
                {bidModify.map((bid, index) => {
                  return (
                      <div className="row-item" key={bid.id}>
                        <div className="row">
                          <div className="col-sm-4">
                            <WizardInputWithValidation
                                type='number'
                                error={handleArrayHaveError(errors, BID_MODIFY_FIELD, VALUE_FIELD, index)}
                                errorMessage={handleArrayErrorMessage(errors, BID_MODIFY_FIELD, VALUE_FIELD, index)}
                                name={`${BID_MODIFY_FIELD}[${index}][${VALUE_FIELD}]`}
                                propRef={register()}
                                placeholder='Ex: 1000'
                            />
                          </div>
                          <div className="col-sm-8">
                            <WizardSelectWithValidation
                                control={control}
                                error={handleArrayHaveError(errors, BID_MODIFY_FIELD, GEOS_FIELD, index)}
                                errorMessage={handleArrayErrorMessage(errors, BID_MODIFY_FIELD, GEOS_FIELD, index)}
                                isMulti={true}
                                name={`${BID_MODIFY_FIELD}[${index}][${GEOS_FIELD}]`}
                                options={bidCountries}
                                selectType={SELECT_TYPE}
                                filterSelected={filterAllList}
                                defaultValue={bid[GEOS_FIELD]}
                            />
                          </div>
                        </div>
                        <span
                            onClick={() => removeBid(index)}
                            className="action-icon delete-item"
                        >
                        <i className="far fa-trash-alt"/>
                      </span>
                      </div>
                  );
                })}
              </div>


            </div>
          </div>
          <div className="group-item pt10">
            <div className="form-group">
              <div className="header-width-add">
                <label>SubId Bid modify</label>
                <div className="add-new">
                  <p onClick={() => appendSubidBid({
                    value: 0,
                    subids: []
                  })}
                  >
                    <i className="fas fa-plus"/>
                    <span className="add-item">Add new</span>
                  </p>
                </div>
              </div>
              <div className="row-list">
                {subidBidModify.map((bid, index) => {
                  return (
                      <div className="row-item" key={bid.id}>
                        <div className="row">
                          <div className="col-sm-4">
                            <WizardInputWithValidation
                                type='number'
                                error={handleArrayHaveError(errors, SUBID_BID_MODIFY_FIELD, VALUE_FIELD, index)}
                                errorMessage={handleArrayErrorMessage(errors, SUBID_BID_MODIFY_FIELD, VALUE_FIELD, index)}
                                name={`${SUBID_BID_MODIFY_FIELD}[${index}][${VALUE_FIELD}]`}
                                propRef={register()}
                                placeholder='Ex: 1.1'
                            />
                          </div>
                          <div className="col-sm-8">
                            <WizardSelectWithValidation
                                control={control}
                                error={handleArrayHaveError(errors, SUBID_BID_MODIFY_FIELD, 'subids', index)}
                                errorMessage={handleArrayErrorMessage(errors, SUBID_BID_MODIFY_FIELD, 'subids', index)}
                                isMulti={true}
                                name={`${SUBID_BID_MODIFY_FIELD}[${index}][${'subids'}]`}
                                options={[]}
                                selectType={CREATE_TYPE}
                                filterSelected={filterAllList}
                                defaultValue={bid['subids']}
                            />
                          </div>
                        </div>
                        <span
                            onClick={() => removeSubidBid(index)}
                            className="action-icon delete-item"
                        >
                                <i className="far fa-trash-alt"/>
                              </span>
                      </div>
                  );
                })}
              </div>

            </div>
          </div>
          <div className="group">
            <h4>Masking</h4>
            <div className="group-item">
              <WizardSelectWithValidation
                  name={SUB_ID_DB_FIELD}
                  control={control}
                  label='SubId Masking'
                  selectType={CREATE_TYPE}
                  error={!!errors[SUB_ID_DB_FIELD]}
                  errorMessage={errors[SUB_ID_DB_FIELD] ? errors[SUB_ID_DB_FIELD].message : ''}
                  isMulti={true}
                  options={domainMaskingOptions}
                  filterSelected={filterAllList}
                  defaultValue={defaultValues[SUB_ID_DB_FIELD]}
              />
            </div>

            <div className="group-item">
              <WizardSelectWithValidation
                  name={REDIRECT_DOMAINS_FIELD}
                  control={control}
                  label='Redirect Domain Override'
                  selectType={CREATE_TYPE}
                  error={!!errors[REDIRECT_DOMAINS_FIELD]}
                  errorMessage={errors[REDIRECT_DOMAINS_FIELD] ? errors[REDIRECT_DOMAINS_FIELD].message : ''}
                  isMulti={true}
                  options={redirectDomainOptions}
                  filterSelected={filterRedirectDomains}
                  defaultValue={defaultValues[REDIRECT_DOMAINS_FIELD]}
              />
            </div>

            <div className="group-item">
              <WizardSelectWithValidation
                  name={DMS_DB_FIELD}
                  control={control}
                  label='Domain Masking'
                  selectType={CREATE_TYPE}
                  error={!!errors[DMS_DB_FIELD]}
                  errorMessage={errors[DMS_DB_FIELD] ? errors[DMS_DB_FIELD].message : ''}
                  isMulti={true}
                  options={domainMaskingOptions}
                  filterSelected={filterAllList}
                  defaultValue={defaultValues[DMS_DB_FIELD]}
              />
            </div>
            <div className="group-item pt10">
              <div className="form-group">
                <div className="header-width-add">
                  <label>DMS DB Weights</label>
                  <div className="add-new">
                    <p onClick={() => appendDmsDbWeights({
                      'dms_domain': [],
                      value: 0
                    })}
                    >
                      <i className="fas fa-plus"/>
                      <span className="add-item">Add new</span>
                    </p>
                  </div>
                </div>
                <div className="row-list">
                  {dmsDbWeights.map((dmsWeight, index) => {
                    return (
                        <div className="row-item" key={dmsWeight.id}>
                          <div className="row">
                            <div className="col-sm-8">
                              <WizardSelectWithValidation
                                  control={control}
                                  error={handleArrayHaveError(errors, DMS_DB_WEIGHTS_FIELD, 'dms_domain', index)}
                                  errorMessage={handleArrayErrorMessage(errors, DMS_DB_WEIGHTS_FIELD, 'dms_domain', index)}
                                  isMulti={true}
                                  name={`${DMS_DB_WEIGHTS_FIELD}[${index}][${'dms_domain'}]`}
                                  options={[...values[DMS_DB_FIELD],{label:'Rest', value: 'rest'}]}
                                  selectType={CREATE_TYPE}
                                  filterSelected={filterDMSDomainWeight}
                                  defaultValue={dmsWeight['dms_domain']}
                              />
                            </div>
                            <div className="col-sm-4">
                              <WizardInputWithValidation
                                  type='number'
                                  error={handleArrayHaveError(errors, DMS_DB_WEIGHTS_FIELD, VALUE_FIELD, index)}
                                  errorMessage={handleArrayErrorMessage(errors, DMS_DB_WEIGHTS_FIELD, VALUE_FIELD, index)}
                                  name={`${DMS_DB_WEIGHTS_FIELD}[${index}][${VALUE_FIELD}]`}
                                  propRef={register()}
                                  placeholder='Ex: 0.1'
                              />
                            </div>
                          </div>
                          <span
                              onClick={() => removeDmsDbWeights(index)}
                              className="action-icon delete-item"
                          >
                                <i className="far fa-trash-alt"/>
                              </span>
                        </div>
                    );
                  })}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  );
};

WizardOptimizationForm.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  parentWizard: PropTypes.oneOf([ENDPOINT_WIZARD, DEMAND_WIZARD]).isRequired,
  setIsValid: PropTypes.func.isRequired
};

export default WizardOptimizationForm;
