import {
    BID_MODIFY_FIELD,
    CARRIERS_FIELD,
    CLICK_LIMITS_FIELD,
    CTYPES_FIELD,
    CUSTOM_TAGS_FIELD,
    DEMAND_FIELDS_FIELD,
    DEMAND_INTEGRATION_FIELD,
    DEVICE_TYPES_FIELD,
    DMS_DB_FIELD,
    DOMAIN_FIELD,
    GEOS_FIELD,
    INCLUDE_FIELD,
    IP_FIELD,
    LIMITS_FIELD,
    LIST_FIELD,
    MAX_BID_FIELD,
    MIN_BID_FIELD,
    OPTIMIZATION_FIELD,
    PROXIES_FIELD,
    REDIRECT_DOMAINS_FIELD,
    REV_SHARE_FIELD,
    SELECTED_FIELD,
    SUB_ID_DB_FIELD,
    SUB_ID_FIELD,
    SUBID_BID_MODIFY_FIELD,
    DMS_DB_WEIGHTS_FIELD,
    USER_AGENTS_FIELD,
    VALUE_FIELD,
    VERSIONS_FIELD
} from '../constants';

import { transformMultiSelect, transformSingleSelect } from './wizard-helpers';

const transformList = (data) => {
  if (!data) {
    return [];
  }

  return data.reduce((acc, item) => {
    const selected = transformSingleSelect(item[SELECTED_FIELD]);

    acc.push({ [selected]: transformMultiSelect(item[VERSIONS_FIELD]) });

    return acc;
  }, []);
};

export const transformTargetingData = (data) =>
  Object.keys(data)
    .reduce((acc, field) => {
      if (field === GEOS_FIELD ||
        field === CTYPES_FIELD ||
        field === CARRIERS_FIELD ||
        field === DEVICE_TYPES_FIELD ||
        field === CUSTOM_TAGS_FIELD
      ) {
        acc[field] = {
          [INCLUDE_FIELD]: data[field][INCLUDE_FIELD],
          [LIST_FIELD]: transformMultiSelect(data[field][LIST_FIELD])
        };

        return acc;
      }

      acc[field] = {
        [INCLUDE_FIELD]: data[field][INCLUDE_FIELD],
        [LIST_FIELD]: transformList(data[field][LIST_FIELD])
      };

      return acc;
    }, {});

const transformOptimizationArray = (data, field = GEOS_FIELD) => {
  if (!data) {
    return [];
  }

  return data.reduce((acc, item) => {
    acc.push({
      [VALUE_FIELD]: parseFloat(item[VALUE_FIELD]),
      [field]: transformMultiSelect((item[field]))
    });
    return acc;
  }, []);
};

export const transformOptimizationsData = (data) =>
  Object.keys(data)
    .reduce((acc, field) => {
      if (field === MIN_BID_FIELD || field === MAX_BID_FIELD) {
        acc[field] = parseFloat(data[field]);

        return acc;
      }

      if (field === REDIRECT_DOMAINS_FIELD || field === DMS_DB_FIELD || field === SUB_ID_DB_FIELD) {
        acc[field] = transformMultiSelect(data[field]);
        return acc;
      }

        if (field === SUBID_BID_MODIFY_FIELD && Array.isArray(data[field])) {
            acc[field] = transformOptimizationArray(data[field],'subids');
            return acc;
        }

        if (field === DMS_DB_WEIGHTS_FIELD && Array.isArray(data[field])) {
            acc[field] = transformOptimizationArray(data[field],'dms_domain');
            return acc;
        }

      if (Array.isArray(data[field])) {
        acc[field] = transformOptimizationArray(data[field]);
        return acc;
      }

      acc[field] = data[field];
      return acc;
    }, {});

export const transformFiltersData = (data) =>
  Object.keys(data)
    .reduce((acc, field) => {
      if (field === IP_FIELD || field === SUB_ID_FIELD || field === DOMAIN_FIELD || field === USER_AGENTS_FIELD) {
        acc[field] = data[field]
          .split('\n')
          .filter(e => !!e);

        return acc;
      }

      if (field === PROXIES_FIELD) {
        acc[field] = transformSingleSelect(data[field]);

        return acc;
      }

      acc[field] = data[field];

      return acc;
    }, {});

export const transformDemandSetupData = (setupData) =>
  Object.keys(setupData)
    .reduce((acc, setupField) => {
      const setupFieldData = setupData[setupField];

      acc[setupField] = Object.keys(setupFieldData)
        .reduce((acc, dataField) => {
          if (dataField === DEMAND_FIELDS_FIELD) {
            acc[dataField] = setupFieldData[dataField].map((field) => ({
              [field.name]: field.value
            }));

            return acc;
          }

          if (dataField === DEMAND_INTEGRATION_FIELD) {
            acc[dataField] = transformSingleSelect(setupFieldData[dataField]);

            return acc;
          }

          acc[dataField] = setupFieldData[dataField];
          return acc;
        }, {});

      return acc;
    }, {});

const parseToNumber = (value) => typeof value === 'string' ? value.replace(',', '.') : value;

const transformOptimizationNumberFields = (optimization) =>
  Object.keys(optimization)
    .reduce((acc, field) => {
      if (field === BID_MODIFY_FIELD || field === LIMITS_FIELD || field === REV_SHARE_FIELD || field === CLICK_LIMITS_FIELD) {
        acc[field] = optimization[field]
          .map(e => ({
            value: parseFloat(parseToNumber(e.value)),
            geos: e.geos
          }));

        return acc;
      }

        if (field === SUBID_BID_MODIFY_FIELD) {
            acc[field] = optimization[field]
                .map(e => ({
                    value: parseFloat(parseToNumber(e.value)),
                    subids: e.subids
                }));

            return acc;
        }

        if (field === DMS_DB_WEIGHTS_FIELD) {
            acc[field] = optimization[field]
                .map(e => ({
                    value: parseFloat(parseToNumber(e.value)),
                    dms_domain: e.dms_domain
                }));

            return acc;
        }

      if (field === REDIRECT_DOMAINS_FIELD || field === DMS_DB_FIELD || field === SUB_ID_DB_FIELD) {
        acc[field] = optimization[field];
        return acc;
      }

      if (optimization[field] !== '' && optimization[field] !== null) {
        acc[field] = parseFloat(parseToNumber(optimization[field]));
      }

      return acc;
    }, {});

export const transformNumberFields = (payload) =>
  Object.keys(payload)
    .reduce((acc, field) => {
      if (field === REV_SHARE_FIELD) {
        acc[field] = parseFloat(parseToNumber(payload[field]));
        return acc;
      }

      if (field === OPTIMIZATION_FIELD) {
        acc[field] = transformOptimizationNumberFields(payload[field]);
        return acc;
      }

      acc[field] = payload[field];
      return acc;
    }, {});
